<template>
  <div>
    <b-card class="card-statistics">
      <app-collapse>
        <app-collapse-item title="Filtros">
          <b-row>
            <b-col md="12">
              <label>Credores</label>
              <v-select
                id="forma-recebimento"
                v-model="creditorsFilter"
                :options="creditors"
                :close-on-select="false"
                multiple
                class="mb-1"
              />
            </b-col>
            <b-col md="4">
              <label for="example-datepicker">Data inicial</label>
              <b-form-input
                id="example-datepicker"
                v-model="date.date_initial"
                locale="pt"
                type="date"
                class="mb-1"
              />
            </b-col>
            <b-col md="4">
              <label for="example-datepicker">Data final</label>
              <b-form-input
                id="example-datepicker"
                v-model="date.date_finaly"
                locale="pt"
                type="date"
                class="mb-4"
              />
            </b-col>
            <b-col
              md="4"
              class="mt-2"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                @click="filter(), load = true"
              >
                Filtrar
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                @click="resetFilter"
              >
                Resetar filtro
              </b-button>
            </b-col>
          </b-row>
        </app-collapse-item>
      </app-collapse>
    </b-card>

    <b-card v-show="showListFiltered">
      <b-col>
        <h3>Relatório de Devoluções</h3>
      </b-col>
      <b-card
        class="padding-0"
        padding="0"
      >
        <b-col
          md="2"
          class="mx-auto"
        >
          <b-alert
            variant="danger"
            show
          >
            <h4 class="alert-heading">
              Devoluções: {{ totalRows }}
            </h4>
          </b-alert>
        </b-col>
        <b-row>
          <b-col
            md="4"
          >
            <label>Filtro por motivo</label>
            <v-select
              v-model="motivosFiltro"
              :options="motivoDevolucoes"
              multiple
            />
          </b-col>
          <b-col class="align-items-bottom">
            <b-button
              variant="outline-warning"
              @click="exportFile"
            >
              Exportar excel
            </b-button>
          </b-col>
          <b-col
            class="mt-2"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filtro"
                debounce="200"
                type="search"
                placeholder="Pesquisar"
              />
            </b-input-group>
          </b-col>
        </b-row>
      </b-card>
      <b-table
        small
        striped
        responsive
        :fields="fields"
        :items="listaComFiltro"
        :per-page="itemPerPage"
        :current-page="currentPage"
        :filter="filtro"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template #cell(data_vencimento)="data">
          {{ formatTimezone(data.item.data_vencimento) }}
        </template>
        <template #cell(data_devolucao)="data">
          {{ formatTimezone(data.item.data_devolucao) }}
        </template>
      </b-table>
    </b-card>
    <b-pagination
      v-model="currentPage"
      :per-page="itemPerPage"
      :total-rows="totalRows"
      align="center"
      size="sm"
      class="my-0"
    />
    <b-overlay
      :show="load"
      variant="dark"
      rounded="sm"
    />

  </div>
</template>

<script>
import {
  BCard, BButton, BFormInput, BPagination, BTable, BRow, BCol, BOverlay, BInputGroup, BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { saveAs } from 'file-saver'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import axios from '@/../axios-auth'

const ExcelJS = require('exceljs')

export default {
  components: {
    BCol,
    BRow,
    BTable,
    BPagination,
    BCard,
    AppCollapse,
    AppCollapseItem,
    BButton,
    BFormInput,
    BOverlay,
    vSelect,
    BInputGroup,
    BAlert,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      motivosFiltro: [],
      totalRows: 1,
      currentPage: 1,
      itemPerPage: 20,

      itemsReport: [],
      showListFiltered: false,
      fields: [
        { key: 'credor_nome', label: 'CREDOR', class: 'text-center' },
        { key: 'cliente_nome', label: 'CLIENTE', class: 'text-center' },
        { key: 'cpf_cnpj', label: 'CPF', class: 'text-center' },
        { key: 'numero_operacao', label: 'NÚMERO DA OPERAÇÃO', class: 'text-center' },
        { key: 'valor_nominal', label: 'VALOR NOMINAL', class: 'text-center' },
        { key: 'data_vencimento', label: 'DATA VENCIMENTO', class: 'text-center' },
        { key: 'motivo_devolucao', label: 'MOTIVO DEVOLUÇÃO', class: 'text-center' },
        { key: 'data_devolucao', label: 'DATA DEVOLUÇÃO', class: 'text-center' },
      ],
      date: {
        date_initial: '',
        date_finaly: '',
      },
      creditors: [],
      creditorsFilter: [],
      load: false,
      filtro: null,
      filterOn: [],
    }
  },

  computed: {
    motivoDevolucoes() {
      const { itemsReport } = this
      const motivos = []

      itemsReport.map(item => {
        motivos.push(item.motivo_devolucao)
      })

      const retiraDuplicados = [...new Set(motivos)]
      return retiraDuplicados
    },

    listaComFiltro() {
      const { itemsReport, motivosFiltro: mf } = this

      let itens = itemsReport

      if (mf.length > 0) {
        itens = []

        for (let i = 0; i <= mf.length - 1; i++) {
          itemsReport.map(item => {
            if (item.motivo_devolucao === mf[i]) {
              itens.push(item)
            }
          })
        }
      }
      this.totalRows = itens.length
      return itens
    },
  },

  async created() {
    await axios.get('api/v1/credores/listar/false', {
      headers: {
        Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        accept: 'application/json',
      },
    }).then(res => {
      res.data.dados.map(item => {
        this.creditors.push({
          label: item.nome,
          id: item.id,
        })
      })
    })

    const filter = JSON.parse(localStorage.getItem('filter'))
    this.creditorsFilter = filter.creditors
    this.date.date_initial = filter.dataInitial
    this.date.date_finaly = filter.dataFinaly
  },

  methods: {
    async filter() {
      const cred = []
      this.creditorsFilter.map(creditor => {
        cred.push(creditor.id)
      })

      const filterDetails = {
        credores_id: cred,
        data_inicio: this.date.date_initial,
        data_final: this.date.date_finaly,
      }

      if (
        filterDetails.credores_id.length === 0
      ) {
        this.$swal({
          icon: 'error',
          title: 'Erro na seleção',
          text: 'Selecione o credor',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      } else {
        await axios.post('api/v1/relatorios/backoffice/devolucoes', filterDetails, {
          headers: {
            Authorization: 'Bearer '.concat(localStorage.getItem('token')),
            accept: 'application/json',
          },
        }).then(res => {
          this.load = false
          this.showListFiltered = true
          if (res.data.mensagem) {
            this.itemsReport = res.data.dados
            this.totalRows = res.data.dados.length
          } else {
            this.itemsReport = res.data
            this.totalRows = res.data.length
          }
        }).catch()

        const remember = {
          dataInitial: filterDetails.data_inicio,
          dataFinaly: filterDetails.data_final,
          creditors: this.creditorsFilter,
        }

        localStorage.setItem('filter', JSON.stringify(remember))
      }
    },

    resetFilter() {
      this.creditorsFilter = []
      this.date.date_initial = ''
      this.date.date_finaly = ''
      localStorage.removeItem('filter')
    },

    async exportFile() {
      const file = await this.getDataToExported()
      if (file) {
        saveAs(file)
      }
    },
    async getDataToExported() {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('Relatório')
      const itemsPlan = this.listaComFiltro

      worksheet.columns = [
        { header: 'CREDOR', key: 'credor_nome' },
        { header: 'CLIENTE', key: 'cliente_nome' },
        { header: 'CPF', key: 'cpf_cnpj' },
        { header: 'NÚMERO DA OPERAÇÃO', key: 'numero_operacao' },
        { header: 'VALOR NOMINAL', key: 'valor_nominal' },
        { header: 'DATA VENCIMENTO', key: 'data_vencimento' },
        { header: 'MOTIVO DEVOLUÇÃO', key: 'motivo_devolucao' },
        { header: 'DATA DEVOLUÇÃO', key: 'data_devolucao' },
        { header: 'COMPETÊNCIA', key: 'competencia' },
        { header: 'DESCRIÇÃO', key: 'descricao' },
        { header: 'LOCALIZAÇÃO', key: 'localizacao' },
      ]
      const wk1Columns = [
        'valor_nominal',
      ]
      const totais = {
        wk1: {},
      }
      itemsPlan.map(item => {
        worksheet.addRow([
          item.credor_nome,
          item.cliente_nome,
          item.cpf_cnpj,
          item.numero_operacao,
          parseFloat(item.valor_nominal),
          this.formatTimezone(item.data_vencimento),
          item.motivo_devolucao,
          this.formatTimezone(item.data_devolucao),
          item.competencia,
          item.descricao,
          item.localizacao,
        ])
        wk1Columns.map(w => {
          totais.wk1[w] ? totais.wk1[w] += parseFloat(item[w]) : totais.wk1[w] = parseFloat(item[w])
        })
      })

      worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
        row.eachCell({ includeEmpty: true }, cell => {
          if (rowNumber === 1) {
            cell.font = {
              name: 'Calibri',
              family: 2,
              bold: true,
              size: 11,
              color: { argb: 'FFFFFF' },
            }
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'fd8426' },
            }
          }
          cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
          }
          cell.alignment = {
            horizontal: 'center',
            vertical: 'center',
          }
        })
      })
      const totalRowWk1 = worksheet.addRow({
        valor_nominal: totais.wk1.valor_nominal,
      })
      totalRowWk1.font = {
        name: 'Calibri',
        family: 2,
        bold: true,
        size: 11,
      }
      totalRowWk1.eachCell({ includeEmpty: false }, cell => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'F4A460' },
        }
        cell.alignment = {
          horizontal: 'center',
          vertical: 'center',
        }
      })
      wk1Columns.map(key => {
        worksheet.getColumn(`${key}`).numFmt = '"R$"#,##0.00;[Red]-"R$"#,##0.00'
      })

      worksheet.columns.forEach(column => {
        let dataMax = 0
        column.eachCell({ includeEmpty: true }, cell => {
          const columnLength = cell.value ? cell.value.length : 15
          if (columnLength > dataMax) { dataMax = columnLength + 5 }
        })
        column.width = dataMax < 15 ? 15 : dataMax
      })

      let blob = null

      await workbook.xlsx.writeBuffer().then(data => {
        // eslint-disable-next-line camelcase
        blob = new File([data], 'Relatório de Devoluções', { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      })
      return blob
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

  },

}
</script>

<style>
  .align-items-bottom{
    display: flex;
    align-items: flex-end;
  }
</style>
